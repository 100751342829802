import styled from 'styled-components';

export const Container = styled.div`

   max-width: 414px;
   min-width: 414px;
   border-radius: 10px;
   display: flex;
   align-content: center;
   align-items: center;
   justify-content: center;
   position: relative;
   cursor: pointer;
   margin-bottom: 120px;
   overflow: hidden;
   background: #fff;

   @media only screen and (max-width: 900px) {
    max-width: 100%;    
    min-width: 100%;
  }

   span{
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bold;
      display: none;
      align-items: center;
      justify-content: center;
      background: rgba(14, 58, 92, 0.4);
      text-shadow: 0 0 10px #000;
      position: absolute;
      top: 0;
      left: 0;   
   }

   &:hover{
      span{
         display: inline-flex;
      }      
   }

`;
