import styled from 'styled-components';

export const MainMenu = styled.div`
  display: flex;
  position: relative;
  float: right;
  align-items: flex-end;
  
  .menu-mobile {
    display: none;
    cursor: pointer;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const NavList = styled.ul`
  
  height: 100%;
  display: flex;
  align-items: flex-end;
  @media (max-width: 768px) {
    width: 220px;
    background: #fff;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 70px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: #707070;
    border-radius: 4px;
    display: ${(props) => (props.visible ? 'inline-table' : 'none')};

    &::before {
      content: '';
      position: absolute;
      right: 0px;
      top: -6px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }
  }
`;

export const NavItem = styled.li`
  display:flex;
  height: 100%;
  align-items: flex-end;
  a{
    padding: 15px 15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    font-size: 16px;
    color: #1B99A4;

    &:hover{
      background: #1B99A4;
      color: #fff;
    }
  }

  @media (max-width: 768px) {

    height: auto;
    a{
      border-radius: 6px;
      width: 100%;
      padding: 15px;
    }
  }
  
`;
