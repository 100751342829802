import React from "react";

import SEO from "src/components/Seo";
import Default from 'src/pages/_layouts/Default';
import MainTitle from 'src/components/MainTitle';
import Content from 'src/components/Content';

import Portfolio from 'src/components/Portfolio';

export default function IndexPage() {
  return (
    <Default >
      <SEO title="Portfólio" />
      <MainTitle title="Portfólio" />
      <Content>
        <Portfolio />
      </Content>
    </Default >
  );
}
