import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// import { Container } from './styles';

export default function BackgroundInterno() {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "background-topo-interno.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Img fluid={data.placeholderImage.childImageSharp.fluid} />
  );
}