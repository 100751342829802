import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: translateX(20%);
    opacity: 0;
  }

  25% {
    transform: translateX(0%);
    opacity: .7;
  }

  50% {
    transform: translateX(-20%);
    opacity: 0;
  }

  100% {
    transform: translateX(-20%);
    opacity: 0;
  }


`;


export const Container = styled.div`
  animation: ${rotate} 10s linear infinite;
  opacity: 0;

  & + .cloud{
    animation-delay: 2.5s;
  }

  & + .cloud + .cloud{
    animation-delay: 5s;
  }
`;  
