import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';
import Item from './components/Item';
import Modal from './components/Modal';

import { Container, Category } from './styles';

export default function Portfolio() {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});

  useEffect(() => {
    function handleItems() {
      axios
        .get('https://apisite.mtistudio.com/portfolio.php')
        .then((r) => {
          setItems(r.data.jobs);
          setCategories(r.data.categories);
          //setSelectedJob(r.data.jobs[0]);
        })
        .catch((r) => {
          toast.error(
            'Houve um problema ao trazer as informações. Tente novamente mais tarde.',
            { position: toast.POSITION.BOTTOM_RIGHT }
          );
        });
    }
    handleItems();
  }, []);

  console.log(items);

  function handleModal(job) {
    setSelectedJob(job);
    setModal(true);
  }
  function handleClose() {
    setModal(false);
    setSelectedJob({});
  }
  return (
    <Container>
      {categories.map((category, k) => (
        <>
          <Category key={category.id}>
            <span>{category.title}</span>
          </Category>
          {items.map(
            (item, key) =>
              category.id === item.category && (
                <Item
                  key={key}
                  bg={item.imgs[0]}
                  name={item.title}
                  folder={item.folder}
                  onClick={() => handleModal(item)}
                />
              )
          )}
        </>
      ))}
      <div className="divider"></div>
      <Modal show={modal} item={selectedJob} close={handleClose} />
    </Container>
  );
}
