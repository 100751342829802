import styled, { keyframes } from 'styled-components';

const animateBubble = keyframes`
  0% {
      margin-top: 150%;
  }
  100% {
      margin-top: -100%;
  }
`;

const sideWays = keyframes`
  0% { 
      margin-left:0px;
  }
  100% { 
      margin-left:50px;
  }
`;

export const Container = styled.div`
  
&.x1 {
	animation: ${animateBubble} 25s linear infinite, ${sideWays} 2s ease-in-out infinite alternate;
	
	left: -5%;
	top: 5%;
	
	-webkit-transform: scale(0.6);
	-moz-transform: scale(0.6);
	transform: scale(0.6);
}

&.x2 {
	animation: ${animateBubble} 20s linear infinite, ${sideWays} 4s ease-in-out infinite alternate;
	
	left: 5%;
	top: 80%;
	
	-webkit-transform: scale(0.4);
	-moz-transform: scale(0.4);
	transform: scale(0.4);
}

&.x3 {
	animation: ${animateBubble} 28s linear infinite, ${sideWays} 2s ease-in-out infinite alternate;
	
	left: 10%;
	top: 40%;
	
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	transform: scale(0.7);
}

&.x4 {
	animation: ${animateBubble} 22s linear infinite, ${sideWays} 3s ease-in-out infinite alternate;
	
	left: 20%;
	top: 0;
	
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	transform: scale(0.3);
}

&.x5 {
	animation: ${animateBubble} 29s linear infinite, ${sideWays} 4s ease-in-out infinite alternate;
	
	left: 30%;
	top: 50%;
	
	-webkit-transform: scale(0.5);
	-moz-transform: scale(0.5);
	transform: scale(0.5);
}

&.x6 {
	animation: ${animateBubble} 21s linear infinite, ${sideWays} 2s ease-in-out infinite alternate;
	
	left: 50%;
	top: 0;
	
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	transform: scale(0.8);
}

&.x7 {
	animation: ${animateBubble} 20s linear infinite, ${sideWays} 2s ease-in-out infinite alternate;
	
	left: 65%;
	top: 70%;
	
	-webkit-transform: scale(0.4);
	-moz-transform: scale(0.4);
	transform: scale(0.4);
}

&.x8 {
	animation: ${animateBubble} 22s linear infinite, ${sideWays} 3s ease-in-out infinite alternate;
	
	left: 80%;
	top: 10%;
	
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	transform: scale(0.3);
}

&.x9 {
	animation: ${animateBubble} 29s linear infinite, ${sideWays} 4s ease-in-out infinite alternate;
	
	left: 90%;
	top: 50%;
	
	-webkit-transform: scale(0.6);
	-moz-transform: scale(0.6);
	transform: scale(0.6);
}

&.x10 {
	animation: ${animateBubble} 26s linear infinite, ${sideWays} 2s ease-in-out infinite alternate;
	
	left: 80%;
	top: 80%;
	
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	transform: scale(0.3);
}


	border-radius: 50%;
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, .3);
  height: 50px;
	position: absolute;
	width: 50px;
  z-index: 2;

  &:after {
      background: radial-gradient(ellipse at center,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 70%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */


    border-radius: 50%;

    box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.1);
    
    content: "";
      height: 70px;
      left: 10px;
      position: absolute;
      width: 70px;
  }
`;
