import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
  display: flex;

  a + a{
    margin-left: 10px;
  }
`;
