import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: -50px;
  padding-top: 70px;

  .cloud{
    position: absolute;
    z-index: 1;
    width: 20%; 
  }

  .cloud_1{
    left: 17%;
    top: 20%;
  }

  .cloud_2{
    left: 50%;
    top: 40%;
  }

  .cloud_3{
    left: 60%;
    top: 10%;
  }

  h1{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: #1B99A4;
    color: #fff;
    padding: 10px 30px;
    text-transform: uppercase;

    
  }
`;
