import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  height: 72px;
  border-top: 22px solid #D4F1F9;
  width: 100%;
  background: #fff;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  z-index: 9999;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  max-width: 1130px;
  justify-content: space-between;
  position: relative;
  
  @media only screen and (max-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 100%;  
  }

`;

export const Left = styled.div`
  display: flex;
  height: 100%;
`;

export const TopLogo = styled.div`
  width: 150px;
  display: inline-table;
  background: #fff;
  padding:20px;
  border-radius: 20px;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 100px;
    border-radius: 10px;
  }

  &::after{
    width: 100%;
    height: 30px;
    content: '';
    background: rgba(0,0,0,0.1);
    bottom: -10px;
    position: absolute;
    left: 0;
    z-index: -1;
    border-radius: 20px;
    filter: blur(5px);
  }
`;

export const Languages = styled.ul`
  position: absolute;
  right: 0;
  top: 50px;
  background: #fff;
  padding:0px;
  border-radius: 0 0 20px 20px;
  display: flex;
  li{
    padding: 10px 20px;
    a{
      span.flag_icon{
        width: 2.33333333em;
        line-height: 2em;
      }
    }
  }
  li+li{
    padding-left: 0;
  }
`;
