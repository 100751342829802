import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

export const Container = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  
`;

export const Content = styled.div`
  max-width: 500px;
  width: 100%;
  max-height: 70%;
  background: #0b1425;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  
  .close{
    position: absolute;
    right: 0px;
    top: 0px;
    background: #0b1425;
    z-index: 99;
    cursor: pointer;

  }
`;

export const Title = styled.h3`
  color: #fff;
  padding: 0 30px;
  font-size: 32px;
`;
export const CustomCarousel = styled(Carousel)`
  flex: 1;
`;

export const Scroll = styled.div`
  overflow-y: auto;
`;
export const Info = styled.div`
  display: flex;
  padding: 0 30px;
  margin-top: 15px;
  flex-wrap: wrap;
  color: #fff;
  & > div > p{
    width: 200px;
    margin-bottom: 10px;
  }

  a{
      color: #129fcc;
      text-decoration: underline;
   }
`;

export const Description = styled.div`
  display: flex;
  & > div{
    flex: 2;
  }
  & > div:first-of-type{
    flex: 1;
    margin-right: 30px;
  }

  ul{
    list-style-type: disc;
    li + li{
      margin-top: 5px;
    }
  }

  

  
`;

export const Tags = styled.div`
  display: flex;
  padding: 0 30px;
  margin: 15px 0;
  span{
    padding: 10px;
    background: #fff;
    color: #0b1425;
    border-radius: 10px;
    & + span{
      margin-left: 5px;
    }
  }
`;