import styled from 'styled-components';
import { Parallax } from 'react-parallax';

export const Main = styled.div`
  min-height: 100%;

  
`;

export const Container = styled.div`
  
  
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  overflow-x: hidden;

`;

export const Water = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(14,58,92,0.4);
    z-index: 1;
`;


export const Para = styled(Parallax)`
  position: relative;
  
  .bg-element{
    position: absolute;
    width: 50%;
    z-index: 0;
  }

  .rocha1{
    top: 0px;
  }
  .rocha2{
    right: 0;
    width: 70%;
    top: 0px;
  }
  .rocha3{
    bottom: 0;
  } 

`;


export const Section = styled.div`
  max-width: 1130px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 270px;
  @media only screen and (max-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 100%;    
  }

  
`;