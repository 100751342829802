import React from 'react';

import { Container } from './styles';
import Bubble from './components/Bubble';

export default function Bubbles() {
  return (
    <Container>
      <Bubble className="x1" />
      <Bubble className="x2" />
      <Bubble className="x3" />
      <Bubble className="x4" />
      <Bubble className="x5" />
      <Bubble className="x6" />
      <Bubble className="x7" />
      <Bubble className="x8" />
      <Bubble className="x9" />
      <Bubble className="x10" />
    </Container>
  );
}
