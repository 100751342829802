import styled, { keyframes } from 'styled-components';

const floating = keyframes`
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }   
`;

export const Container = styled.div`
  position: sticky;
  top: 400px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  margin-bottom: -350px;

  animation-name: ${floating};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  


`;
