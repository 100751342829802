import React, { useState, useEffect } from 'react';
import { MdMenu } from 'react-icons/md';
import { MainMenu, NavList, NavItem } from './styles';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

export default function Menu() {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { language, defaultLanguage } = useI18next();
  const add = language === defaultLanguage ? '' : `/${language}`
  const menuitems = [
    {
      name: t('menus').who_we_are,
      url: '/#quem-somos',
    },
    {
      name: t('menus').areas_of_expertise,
      url: '/#atuacao',
    },
    {
      name: t('menus').portfolio,
      url: '/#portfolio',
    },
    {
      name: t('menus').products,
      url: '/#produtos',
    },
    {
      name: t('menus').clients,
      url: '/#clientes',
    },
    {
      name: t('menus').contact,
      url: '/#contato',
    },
    {
      name: t('menus').blog,
      url: '/blog',
    },
  ];
  const [menus, setMenus] = useState(menuitems);

  useEffect(() => {
    setMenus(menuitems);
  }, []);

  function handleToggleVisible() {
    setVisible(!visible);
  }
  return (
    <MainMenu>
      <MdMenu
        onClick={handleToggleVisible}
        color="#444444"
        size={20}
        className="menu-mobile"
      />
      <NavList visible={visible} onClick={() => setVisible(false)}>
        {menus.map((menu, key) => (
          <NavItem key={String(key)} active={menu.active}>
            <AnchorLink
              to={add + menu.url}
              title={menu.name}
              stripHash


            />
          </NavItem>
        ))}
      </NavList>
    </MainMenu>
  );
}
