import styled from 'styled-components';
import corda from 'src/images/corda.png';

export const Container = styled.div`
  position: relative;
  height: 72px;
  width: 100%;
  background-color: #D4F1F9;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  z-index: 1;

  @media only screen and (max-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
  }


  &::after{
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(${corda});
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    z-index: 0;
  }
  &::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    content: '';
    background-color: #D4F1F9;
    z-index: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  max-width: 1130px;
  justify-content: flex-end;
  z-index: 2;

`;

