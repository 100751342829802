import React from 'react';

import { Container } from './styles';
import { FaTwitter, FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';

export default function Social() {
  return (
    <Container>
      <a href="https://twitter.com/mtistudio" target="_blank" rel="noopener noreferrer">
        <FaTwitter size={30} color="#00A7FA" />
      </a>
      <a href="https://www.instagram.com/mtistudio/" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={30} color="#00A7FA" />
      </a >
      <a href="https://www.facebook.com/mtistudio/" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={30} color="#00A7FA" />
      </a >
      <a href="https://www.linkedin.com/company/mti-studio-ltda" target="_blank" rel="noopener noreferrer">
        <FaLinkedin size={30} color="#00A7FA" />
      </a >
    </Container >
  );
}
