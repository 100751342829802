import React, { useMemo } from 'react';

import { Container } from './styles';

export default function Item({ name, bg, folder, ...rest }) {
  const img = useMemo(
    () => `https://apisite.mtistudio.com/jobs/${folder}/${bg}`,
    [bg]
  );
  return (
    <Container {...rest}>
      <img src={img} alt={name} />
      <span>{name}</span>
    </Container>
  );
}
