import React from 'react';

import { MdClose } from 'react-icons/md';
import {
  Container,
  Content,
  Title,
  Info,
  Tags,
  Description,
  Scroll,
  CustomCarousel,
} from './styles';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

export default function Modal({ show, item, modal, close, ...rest }) {
  const imgs = item.imgs
    ? item.imgs.map(
        (img) =>
          `https://apisite.mtistudio.com/jobs/${item.folder}/${img}`
      )
    : [];
  const tags = item.tags ? item.tags : [];
  return (
    <Container show={show} {...rest}>
      <ScrollLock isActive={show} />

      <Content>
        <MdClose size={30} color="#fff" className="close" onClick={close} />
        <CustomCarousel>
          {imgs.map((img) => (
            <div>
              <img src={img} />
            </div>
          ))}
        </CustomCarousel>
        <Scroll>
          <Title>{item.title}</Title>
          <Info>
            {item.client && (
              <div>
                <h4>Cliente:</h4>
                <p>{item.client}</p>
              </div>
            )}
            <Description
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </Info>
          <Tags>
            {tags.map((tag) => (
              <span>{tag}</span>
            ))}
          </Tags>
        </Scroll>
      </Content>
    </Container>
  );
}
