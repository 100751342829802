import React from "react"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

export default function Default({ children }) {
  return (
    <>
      <Header />

      <ToastContainer />
      <div style={{ minHeight: "100%" }}>{children}</div>
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          width: "calc(100% - 40px)",
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: "20px",
          borderRadius: "10px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
        }}
      >
        Nós utilizamos cookies e tecnologias semelhantes para melhorar a sua
        experiência com os nossos serviços, a fim de personalizar as
        publicidades e recomendar conteúdo de seu interesse, seguindo a nossa{" "}
        <Link
          to="#"
          style={{
            color: "#fff",
            fontWeight: 700,
            textDecoration: "underline",
          }}
        >
          Política de Privacidade
        </Link>
        . Ao continuar navegando neste site, você concorda com tal
        monitoramento.
      </CookieConsent>
      <Footer />
    </>
  )
}
