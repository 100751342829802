import React from 'react';
import Social from "src/components/Social";

import { Container, Content } from './styles';

export default function Footer() {
  return (
    <Container>
      <Content>
        <Social />
      </Content>
    </Container>
  );
}
