import React from 'react';



import fundo from 'src/images/fundo_mar.png';

import { Main, Container, Section, Para, Water } from './styles';
import Polvo from 'src/components/Animations/Polvo';
import Bubbles from 'src/components/Animations/Bubbles';

import rochas1 from 'src/images/fundo_rochas_lado2.png';

import rochas2 from 'src/images/fundo_rochas_lado1.png';

import rochas3 from 'src/images/fundo_rochas_lado2_1.png';

export default function Content({ children }) {

  return (
    <Main>

      <Polvo />
      <Water />
      <Para
        bgImage={fundo}
        strength={2500}
      >
        <img src={rochas1} className="bg-element rocha1" />
        <img src={rochas2} className="bg-element rocha2" />
        <img src={rochas3} className="bg-element rocha3" />
        <Container>
          <Bubbles />
          <Section>
            {children}
          </Section>
        </Container>

      </Para>


    </Main>
  );
}
