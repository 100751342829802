import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import Logo from "src/components/Images/Logo";
import Menu from "src/components/Menu";

import { Container, Content, TopLogo, Languages } from './styles';

export default function Header() {
  const { languages, changeLanguage } = useI18next();
  return (
    <Container>
      <Content>
        <TopLogo>
          <AnchorLink to="/#header"><Logo /></AnchorLink>
        </TopLogo>

        <Menu />
        <Languages>
          {languages.map((lng) => (
            <li key={lng}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  changeLanguage(lng);
                }}>
                <span class={`flag-icon flag-icon-${lng}`}></span>

              </a>
            </li>
          ))}
        </Languages>
      </Content>
    </Container>
  );
}
